<template>
  <v-container fluid>
    <base-loader :isLoading="isLoading"></base-loader>
    <base-dialog-notification ref="confirm" />
    <base-snackbar-notification ref="mainSnackbar" />
    <v-dialog  v-if="showModalFlag" v-model="showModal" fullscreen>
      <v-card :style="{ background: '#fff' }">
        <v-carousel
          light
          :show-arrows="true"
          height="70vh"
          hide-delimiter-background
        >
          <v-carousel-item cover>
            <v-img src="../../assets/Onboarding1.png" />
          </v-carousel-item>
          <v-carousel-item cover>
            <v-img src="../../assets/Onboarding2.png" />
          </v-carousel-item>
          <v-carousel-item cover>
            <v-img src="../../assets/Onboarding3.png" />
          </v-carousel-item>
          <v-carousel-item cover>
            <v-img src="../../assets/Onboarding4.png" />
          </v-carousel-item>
        </v-carousel>
        <v-card-actions class="justify-center">
          <v-btn color="#6dd984" rounded @click="closeModal"
            >Skip</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> 
    <v-row>
      <v-col cols="12" md="6">
        <v-card class="mb-6 select-card" flat>
          <div class="" @click="show = !show">
            <v-row class="px-3 py-3 d-flex align-center">
              <v-avatar class="ml-2" size="42">
                <img
                  v-if="!selectedItem?.profilePicture"
                  :src="defaultProfileImage()"
                  size="48"
                />

                <v-img v-else :src="selectedItem.profilePicture"></v-img>
              </v-avatar>
              <v-spacer></v-spacer>
              <p class="text-h4 mb-0">Manage Employees</p>
              <v-spacer></v-spacer>
              <div class="ico-point">
                <v-icon color="black">{{ show ? "mdi-menu-up" : "mdi-menu-down" }}</v-icon>
              </div>
            </v-row>
          </div>
          <v-expand-transition v-if="users">
              <div v-show="show" class="select-list">
                <v-list-item-group color="primary">
                  <template v-if="users.records.length > 0">
                    <v-list-item
                      v-for="(item, i) in users.records"
                      :key="i"
                      @click="selectedItem = item;"
                    >
                      <v-row
                        ><v-col class="d-flex" cols="8">
                          <v-avatar v-if="item.profilePicture" class="my-4">
                            <img :src="item.profilePicture" size="38" />
                          </v-avatar>
                          <v-avatar v-else class="my-4">
                            <img
                              :src="defaultProfileImage()"
                              color="teal"
                              size="38"
                            />
                          </v-avatar>
                          <p class="my-5 ml-2">
                            {{ item.firstName }} {{ item.lastName }}
                            <br/>
                           <span class="text--secondary">{{ item.jobTitle }}</span>
                          </p>
                        </v-col>
                        <v-col cols="4" class="px-2 d-flex justify-end">
                          <v-btn
                            class="my-4"
                            color="primary"
                            dark
                            small
                            icon
                            elevation="0"
                            @click="editItem(item)"
                            ><v-icon>mdi-pencil</v-icon></v-btn
                          >
                          <v-btn
                            class="my-4"
                            color="orange"
                            dark
                            small
                            icon
                            elevation="0"
                            @click="deleteItem(item)"
                            ><v-icon>mdi-trash-can-outline</v-icon></v-btn
                          >
                        </v-col></v-row
                      >
                    </v-list-item>
                    </template>
                </v-list-item-group>
                <v-list-item @click="selectedItem = null">
                  <v-row>
                    <v-col class="d-flex" cols="9">
                      <template v-if="users.records.length === 0">
                        <span class="pa-2">You don't have any employees setup yet. Click the purple add button to add an employee.</span>
                      </template>
                    </v-col>
                  </v-row>
                </v-list-item>

                <v-dialog v-model="dialog3" fullscreen>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn fab dark small v-bind="attrs" v-on="on" class="createNew" color="primary">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <v-card color="white">
                    <v-toolbar dark color="primary">
                      <v-btn
                          icon
                          dark
                          @click="closeCreateEmployeeDialog"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <v-toolbar-title>Create New Employee</v-toolbar-title>
                      <v-spacer></v-spacer>
                    </v-toolbar>
                      <user-create
                        :employerID="me.id"
                        :createdBy="me.id"
                        :role="'employee'"
                        :formType="'employeeCreate'"
                        :workAddress="me.fullAddress"
                        v-on:closeCreateEmployeeDialog="closeCreateEmployeeDialog"
                        v-on:createEmployeeSuccess="showContractDialog"
                      />
                   </v-card>
                </v-dialog>
                <v-dialog v-model="contractDialog" fullscreen>
                  <v-card color="white">
                    <v-toolbar dark color="primary">
                      <v-btn
                          icon
                          dark
                          @click="closeCreateContractDialog"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <v-toolbar-title>Create New Contract</v-toolbar-title>
                      <v-spacer></v-spacer>
                    </v-toolbar>
                    <base-contract-form
                        v-bind:edit="true"
                        createdById="me.id"
                        :userId="contractDialogUserId"
                        subtitle="Now that you have added an employee you will need to create a contract for them."
                        v-on:contractFormFinished="closeCreateContractDialog"
                        v-on:contractFormCancel="closeCreateContractDialog"
                        class="px-2"
                    />
                  </v-card>
                </v-dialog>
              </div>
          </v-expand-transition>
        </v-card>
        <!------------------------Calendar------------------------->

        <v-card
            class="mb-6"
            flat
            @click='$router.push({ name: "Calendar", params: { userContracts: userContracts }})'
        >
          <v-row>
            <v-col cols="4">
              <div class="ml-3 mt-3">
                <v-img
                  src="../../assets/undraw_online_calendar_re_wk3t.svg"
                ></v-img>
              </div>
            </v-col>
            <v-col cols="8">
              <h3>Calendar</h3>
              <br />
              <p>Track employee payments and days worked</p>
            </v-col></v-row
          >
        </v-card>
        <!-----------------------Accounts-------------------------->
        <div>
          <!-- <h3 style="z-index: 4">Accounts</h3> -->
          <v-card class="mb-6"  flat>
            <v-row @click="$router.push('/employer/paymentHistory')">
              <v-col cols="4">
                <div class="ml-3 mt-3">
                  <v-img
                    src="../../assets/undraw_note_list_re_r4u9 (1).svg"
                  ></v-img>
                </div>
              </v-col>
              <v-col cols="8">
                <h3>Payment History</h3>
                <br />
                <p>Click here to view a full list of your payment history</p>
              </v-col></v-row
            >
            <v-divider></v-divider>
            <v-row
              class="mt-2"
              @click='$router.push({ name: "paymentSchedule", params: { userContracts: userContracts, id: "all" }})'
            >
              <v-col cols="4">
                <div class="ml-3 mt-3">
                  <v-img
                    src="../../assets/undraw_mobile_pay_re_sjb8.svg"
                  ></v-img>
                </div>
              </v-col>
              <v-col cols="8">
                <h3>Upcoming Payments</h3>
                <br />
                <p>Click here to view your upcoming scheduled payments</p>
              </v-col></v-row>
          </v-card>
        </div>

        <!--------------------Shopping List----------------------------->
<!--        <v-row>-->
<!--          <v-col cols="6" v-if="shoppings">-->
<!--            <div-->
<!--              @click="$router.push('/employer/shoppingList')"-->
<!--              v-bind:style="{-->
<!--                'background-color': '#61E786',-->
<!--                'border-radius': '10px',-->
<!--                height: '130px',-->
<!--              }"-->
<!--            >-->
<!--              <v-row>-->
<!--                <v-col cols="4">-->
<!--                  <h1 class="h1-shopping">-->
<!--                    {{ shoppings.count }}-->
<!--                  </h1>-->
<!--                </v-col>-->

<!--                <v-col cols="8"-->
<!--                  ><h3>Shopping list</h3>-->
<!--                  <p>-->
<!--                    Manage your shopping list-->
<!--                  </p></v-col-->
<!--                >-->
<!--              </v-row>-->
<!--            </div>-->
<!--          </v-col>-->
<!--          &lt;!&ndash;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;To Do List-&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&ndash;&gt;-->
<!--          <v-col cols="6" v-if="todolists">-->
<!--            <div-->
<!--              @click="$router.push('/employer/toDoList')"-->
<!--              v-bind:style="{-->
<!--                'background-color': '#61E786',-->
<!--                'border-radius': '10px',-->
<!--                height: '130px',-->
<!--              }"-->
<!--            >-->
<!--              <v-row>-->
<!--                <v-col cols="4">-->
<!--                  <h1 class="h1-shopping">-->
<!--                    {{ todolists.count }}-->
<!--                  </h1>-->
<!--                </v-col>-->

<!--                <v-col cols="8"-->
<!--                  ><h3>To-do list</h3>-->
<!--                  <p>-->
<!--                    You have {{ todolists.count }} items in <br />-->
<!--                    the to-do list-->
<!--                  </p></v-col-->
<!--                >-->
<!--              </v-row>-->
<!--            </div>-->
<!--          </v-col>-->
<!--        </v-row>-->
      </v-col>
      <v-col
        cols="6"
        md="6"
        class="absolute-center"
        v-if="
          $vuetify.breakpoint.md ||
          $vuetify.breakpoint.lg ||
          $vuetify.breakpoint.xl
        "
      >
        <v-img
          contain
          src="../../assets/FairwageLogo.png"
          lazy-src="../../assets/FairwageLogo.png"
        ></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { gql } from "apollo-boost";
import UserCreate from "../../components/Base/UserCreate.vue";
import { User } from "@/models/User";
import defaultProfile from "@/assets/avatar.png";
import defaultGroupProfile from "../../assets/group-avatar.png";
export default {
  components: { UserCreate },
  name: "Home",
  mounted() {
    this.$emit("update:pageName", this.$options.name);
  },
  data() {
    return {
      isLoading: false,
      initialDialog: true,
      show: false,
      calenderDia: false,
      dialog3: false,
      contractDialog: false,
      contractDialogUserId: null,
      selectedItem: null,
      showModalFlag: false,
      userModel: new User(),
      menu: false,
      newItems: [],
      toDoFilter: {},
      shoppingFilter: {},
      users: { records: [] },
      shoppings: false,
      todolists: false,
      userContracts: [],
    };
  },
  created () {
      this.showModal()
    },
  apollo: {
    me: gql`
      query {
        me {
          id
          fullAddress
          role
        }
      }
    `,
    users: {
      query: gql`
        query Users($limit: Int, $query: JSON!) {
          users(limit: $limit, query: $query) {
            records {
              id
              firstName
              lastName
              role
              bankName
              branchCode
              employers
              profilePicture
              email
              telNumber
              employeeID
              accountHolder
              accountNumber
              accountType
              paymentSetupType
              workAddress
              payslipHtml
              contractHtml
              jobTitle

            }
          }
        }
      `,
      variables() {
        return {
          limit: 999999999,
          query: { employers: { $in: [this.me.id] }, role: "employee", deleted: { $ne: true } },
        };
      },
      result({ data }, key) {
        console.log('Data:', data)
        this.newItems = this.$_.get(data, 'users.records', [])
        this.toDoFilter = {
          assignedTo: {
            $in: this.newItems.map((item, i) => {
              return item.id;
            }),
          },
        };
        this.shoppingFilter = {
          assignedTo: {
            $in: this.newItems.map((item, i) => {
              return item.id;
            }),
          },
        };
        console.log('Shopping Fileter:', this.shoppingFilter)
        this.fetchUserContracts(data.users.records)
      },
    },
    user: {
      query: gql`
        query User($id: ID!) {
          user(id: $id) {
            id
            paymentSetupType
            employeeID
          }
        }
      `,
      fetchPolicy: "network-only",
      skip() {
        return !this.$route.params.id || this.$route.params.id == 0;
      },
      variables() {
        const self = this;
        return {
          id: self.$route.params.id,
        };
      },
      watchLoading(isLoading) {
        this.isLoading = isLoading;
      },
      result(response, key) {
        this.isLoading = false;
        this.userModel.id = this.user.id;
      },
    },

    todolists: {
      query: gql`
        query Todolists($limit: Int!, $skip: Int!, $query: JSON!) {
          todolists(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              description
              done
              assignedTo
              assignedToName
              createdBy
              createdByName
              createdAt
              updatedAt
            }
            count
          }
        }
      `,
      fetchPolicy: "network-only",
      variables() {
        const self = this;
        const limit = 9999;
        const skip = 0;
        return {
          limit: limit,
          skip: skip,
          query: this.toDoFilter
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
    },
    shoppings: {
      query: gql`
        query Shoppings($limit: Int!, $skip: Int!, $query: JSON!) {
          shoppings(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              description
              done
              assignedTo
              assignedToName
              createdBy
              createdByName
              createdAt
              updatedAt
            }
            count
          }
        }
      `,
      fetchPolicy: "network-only",
      variables() {
        const self = this;
        const limit = 9999;
        const skip = 0;
        return {
          limit: limit,
          skip: skip,
          query: this.shoppingFilter, //{}, //{ assignedTo: this.me.id },
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
    },
  },

  methods: {
    showModal() {
      // check if modal has been displayed before
      if (localStorage.getItem('modalDisplayed') !== 'true') {
        // set the flag to show the modal
        this.showModalFlag = true;

        // set the localStorage value to indicate that the modal has been displayed
        localStorage.setItem('modalDisplayed', 'true');
      }
    },
    closeModal() {
      // set the flag to hide the modal
      this.showModalFlag = false;
    },
    closeCreateEmployeeDialog (message) {
      if (message) {
        if (this.$_.get(message, 'success')) {
          //this.$refs.mainSnackbar.open(message.success, 'success')
          this.contractDialog = true
        } else if (this.$_.has(message, 'error')) {
          this.$refs.confirm.open('Error', message.error, 'error', 'notification')
        }
      }
      this.dialog3 = false;
      this.$apollo.queries.users.refetch()
    },
    closeCreateContractDialog (message) {
      if (message) {
        if (this.$_.get(message, 'status') === 'success') {
          this.$refs.mainSnackbar.open('The employee and the contract have been successfully created.', 'success')
        } else if (this.$_.get(message, 'status') === 'error') {
          this.$refs.confirm.open('Error', message.status, 'error', 'notification')
        }
      }
      this.contractDialog = false;
      this.$apollo.queries.users.refetch()
    },
    showContractDialog (userId) {
      this.contractDialog = true
      this.contractDialogUserId = userId
    },
    defaultProfileImage() {
      return defaultProfile;
    },

    defaultGroupProfileImage() {
      return defaultGroupProfile;
    },
    async fetchUserContracts (users) {
      this.isLoading = true
      const tempContracts = []
      for (const user of users) {
        await this.getContractByUserId(user.id).then((data) => {
          if (data) {
            tempContracts.push(data)
          }
        }).catch((error) => {
          console.error(error)
        })
      }

      this.userContracts = []
      this.userContracts = tempContracts
      this.isLoading = false
    },
    async getContractByUserId (userId) {
      return new Promise(async (resolve, reject) => {
        this.$apollo.query({
          query: gql`
            query GetContract($userId: ID!) {
              contractByUserId(userId: $userId) {
                id
                userId
                jobTitle
                duties
                contractCurrency
                terminationNoticePeriod
                wagePaymentSchedule
                wagePaymentDate
                wagePaymentWeekDay
                wagePeriod
                wagePeriodUnitType
                wageTracking
                wageRate
                overtimeAvailable
                overtimeRate
                publicHolidayRate
                hoursOfWorkStart
                hoursOfWorkEnd
                lunchPeriod
                createdAt
                updatedAt
              }
            }
          `,
          fetchPolicy: "no-cache",
          variables: {
            userId: userId
          }
        }).then((data) => {
          if (_.has(data, 'data.contractByUserId.id')) {
            return resolve(data.data.contractByUserId)
          } else {
            return reject('No data found')
          }
        }).catch((error) => {
          console.error('Error:', error)
          return reject(error)
        })
      })
    },
    async deleteItem(item) {
      const dialogAnswer = await this.$refs.confirm.open("Warning", "Are you sure you want to delete this Employee?", "warning", "yesno")
      if (dialogAnswer) {
          this.$apollo.mutate({
            mutation: gql`
              mutation ($id: ID!) {
                deleteUser(id: $id) {
                  id
                  deleted
                }
              }
            `,
            variables: {
              id: item.id,
            },
          }).then((result) => {
            console.log('Delete User Result:', result)
              this.$refs.mainSnackbar.open("Employee Deleted.", "success")
              this.$apollo.queries.users.refetch()
          }).catch((error) => {
              this.$refs.mainSnackbar.open("There was a problem deleting the Employee. Please try again or contact support.", "warning")
          })
      }
    },

    editItem(item) {
      this.$router.push(`/employer/employee-profile/${item.id}/edit`);
    },
  },
  watch: {
    selectedItem(newValue) {
      if (newValue) {
        this.toDoFilter = { assignedTo: newValue.id };
        this.shoppingFilter = { assignedTo: newValue.id };
      } else {
        this.toDoFilter = {
          assignedTo: {
            $in: this.newItems.map((item, i) => {
              return item.id;
            }),
          },
        };
        this.shoppingFilter = {
          assignedTo: {
            $in: this.newItems.map((item, i) => {
              return item.id;
            }),
          },
        };
      }
    },
  },
};
</script>

<style scoped>
.v-sheet.v-card {
  background: #61E786;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.ico-point .mdi:before,
.ico-point .mdi-set {
  font-size: 50px;
}

.top-sect .v-btn {
  width: calc(100% - 67px);
  min-height: 50px;
}

/*This controls the styling of selector icon*/
.top-sect .v-btn__content {
  color: #eeecf1 !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  letter-spacing: 0.75px;
}
.top-sect .v-card__actions {
  position: absolute;
  right: 10px;
  top: 10px;
}
.top-sect .v-icon.v-icon {
  color: #ffffff;
}
/*This controls the styling of the top selector*/
.select-card {
  background-color: #6dd984;
  min-height: 30px;
  position: relative;
  padding: 5px 5px;
}
.select-list {
  background-color: #fff;
  margin-top: 10px;
  border-radius: 10px;
}

.h1-shopping {
  font-weight: 400;
  font-size: 64px;
  margin-left: 8px;
  font-weight: bold;
  text-decoration: underline;
  color: #ffff;
  text-shadow: 2px 2px #3f3d56;
}

.createNew {
  position: absolute;
  right: 22px;
  bottom: 16px;
}
</style>
